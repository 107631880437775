/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Telematics API
 * OpenAPI spec version: 1.0.0
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
export type GetStatusParams = {
  farmIds: string;
};

export type GetAccountParams = {
  farmIds?: string;
  "company-id": string;
};

export type GetAll7Params = {
  language?: string;
  farmIds?: string;
  search?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  "per-page"?: number;
  "sort-col"?: CropSortColumn;
  "sort-dir"?: string;
};

export type GetDriverByCodeParams = {
  farmIds: string;
};

export type GetAll6Params = {
  language?: string;
  farmIds: string;
  search?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  "per-page"?: number;
  "sort-col"?: DriverSortColumn;
  "sort-dir"?: string;
};

export type GetEquipmentByCodeParams = {
  farmIds: string;
  language?: string;
};

export type GetAll5Params = {
  language?: string;
  farmIds: string;
  search?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  "per-page"?: number;
  "sort-col"?: EquipmentSortColumn;
  "sort-dir"?: string;
};

export type GetStateParams = {
  farmIds?: string;
  language?: string;
};

export type GetAll4Params = {
  farmIds?: string;
  language?: string;
  page?: number;
  "per-page"?: number;
};

export type Logbook1Params = {
  farmIds: string;
  language?: string;
};

export type LogbookByMachinesParams = {
  farmIds: string;
  page?: number;
  "per-page"?: number;
  "sort-col"?: LogbookAggregatedSortColumn;
  "sort-dir"?: string;
  language?: string;
  dateFrom?: string;
  dateTo?: string;
  driver?: string;
  machine?: string;
  state?: State[];
};

export type AggregatedRecordsParams = {
  farmIds: string;
  page?: number;
  "per-page"?: number;
  "sort-col"?: LogbookDriveSortColumn;
  "sort-dir"?: string;
  language?: string;
  dateFrom?: string;
  dateTo?: string;
  search?: string;
  actionState?: string[];
  crop?: number[];
  operation?: TelematicsOperation[];
  affiliation?: Affiliation[];
  productionOperation?: string[];
  actionType?: string[];
  parcel?: number[];
  parcelSubjectId?: string[];
  driver?: string[];
  gpsUnit?: string[];
  equipment?: string[];
  state?: State[];
};

export type GetDriveSubjectsParams = {
  farmIds: string;
  search?: string;
};

export type GetKeysCombinations1Params = {
  type: KeyType;
  farmIds: string;
};

export type GetKeysParams = {
  farmIds: string;
};

export type GetDriveOverlapsParams = {
  farmIds: string;
};

export type GetClients1Params = {
  search?: string;
  farmIds: string;
  dateFrom?: string;
  dateTo?: string;
};

export type LogbookAggregatedExportParams = {
  farmIds: string;
  language?: string;
  dateFrom?: string;
  dateTo?: string;
  driver?: string;
  machine?: string;
  state?: State[];
};

export type LogbookAggregatedRecordsExportParams = {
  farmIds: string;
  language?: string;
  dateFrom?: string;
  dateTo?: string;
  search?: string;
  actionState?: string[];
  crop?: number[];
  operation?: TelematicsOperation[];
  affiliation?: Affiliation[];
  productionOperation?: string[];
  actionType?: string[];
  parcel?: number[];
  parcelSubjectId?: string[];
  driver?: string[];
  gpsUnit?: string[];
  equipment?: string[];
  state?: State[];
};

export type LogbookExportParams = {
  farmIds: string;
  language?: string;
  dateFrom?: string;
  dateTo?: string;
  search?: string;
  actionState?: string[];
  crop?: number[];
  operation?: TelematicsOperation[];
  affiliation?: Affiliation[];
  productionOperation?: string[];
  actionType?: string[];
  parcel?: number[];
  parcelSubjectId?: string[];
  driver?: string[];
  gpsUnit?: string[];
  equipment?: string[];
  state?: State[];
};

export type TosHealthParams = {
  farmIds: string;
};

export type TosStateParams = {
  farmIds: string;
  language?: string;
};

export type DriveDetailParams = {
  farmIds: string;
  language?: string;
};

export type GetMachineCategoriesParams = {
  language?: string;
};

export type GetMachineByGpsParams = {
  farmIds: string;
  language?: string;
};

export type GetMachineGroupsParams = {
  language?: string;
};

export type GetDashboardParams = {
  farmIds: string;
  search?: string;
  "sort-col"?: PositionSort;
  "sort-dir"?: string;
};

export type GetPositionHistoryParams = {
  farmIds: string;
  drivers?: string[];
  machines?: string[];
  "date-from": string;
  "date-to"?: string;
  extended?: boolean;
};

export type GetAll3Params = {
  farmIds: string;
  drivers?: string[];
  machines?: string[];
};

export type GetAll2Params = {
  farmIds: string;
  language?: string;
  search?: string;
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  "per-page"?: number;
  "sort-col"?: MachineSortColumn;
  "sort-dir"?: string;
};

export type GetAll1Params = {
  farmIds?: string;
  search?: string;
  language?: string;
  handwork?: boolean;
};

export type ValueForKeyParams = {
  language?: string;
};

export type GetAllParams = {
  language?: string;
};

export type GetClientsParams = {
  search?: string;
  farmIds: string;
  dateFrom?: string;
  dateTo?: string;
};

export type GetKeysCombinationsParams = {
  type: KeyType;
  farmIds: string;
};

export type UpdateBonusParams = {
  farmIds: string;
  language?: string;
};

export type UpdateDriver1Params = {
  farmIds: string;
  language?: string;
};

export type UpdateEquipment1Params = {
  farmIds: string;
  language?: string;
};

export type UpdateCustomerKeyParams = {
  farmIds: string;
  language?: string;
};

export type UpdateProductionOperationParams = {
  farmIds: string;
  language?: string;
};

export type UpdateSubjectParams = {
  farmIds: string;
  language?: string;
};

export type CreateHandworkParams = {
  farmIds: string;
  language?: string;
};

export type GetDriverOverlapsParams = {
  farmIds: string;
};

export type SetStateParams = {
  farmIds: string;
};

export type RecalculateAreaParams = {
  farmIds: string;
};

export type SeparateDriveByIntervalParams = {
  farmIds: string;
  language?: string;
};

export type SeparateDriveByTimeParams = {
  farmIds: string;
  language?: string;
};

export type _DeleteParams = {
  farmIds: string;
};

export type UpdateParams = {
  farmIds: string;
  language?: string;
};

export type DriveDetail1Params = {
  farmIds: string;
  language?: string;
};

export type Create1Params = {
  farmIds: string;
  language?: string;
};

export type LogbookByDriversParams = {
  farmIds: string;
  page?: number;
  "per-page"?: number;
  "sort-col"?: LogbookAggregatedSortColumn;
  "sort-dir"?: string;
  language?: string;
  dateFrom?: string;
  dateTo?: string;
  driver?: string;
  machine?: string;
  state?: State[];
};

export type TosExcelExportParams = {
  farmIds: string;
  language?: string;
};

export type TosExportParams = {
  farmIds: string;
  language?: string;
};

export type CreateParams = {
  farmIds: string;
  language?: string;
};

export type LogbookParams = {
  farmIds: string;
  page?: number;
  "per-page"?: number;
  "sort-col"?: LogbookSortColumn;
  "sort-dir"?: string;
  language?: string;
  dateFrom?: string;
  dateTo?: string;
  search?: string;
  actionState?: string[];
  crop?: number[];
  operation?: TelematicsOperation[];
  affiliation?: Affiliation[];
  productionOperation?: string[];
  actionType?: string[];
  parcel?: number[];
  parcelSubjectId?: string[];
  driver?: string[];
  gpsUnit?: string[];
  equipment?: string[];
  state?: State[];
};

export type GetAll8Params = {
  page?: number;
  "per-page"?: number;
  "sort-col"?: AccountSortColumn;
  "sort-dir"?: string;
  search?: string;
  countryCode?: string[];
  enabled?: boolean;
  catalogue?: Catalogue;
  economicSystem?: EconomicSystem;
};

export type UpdateDriverParams = {
  farmIds?: string;
};

export type UpdateEquipmentParams = {
  farmIds?: string;
};

export type UpdateKeysParams = {
  farmIds: string;
};

export type GetParams = {
  farmIds: string;
};

export type UpdateMachineParams = {
  farmIds?: string;
};

export interface AccountStatusTo {
  automaticActions: boolean;
  catalogue?: Catalogue;
  dashboard: boolean;
  economicSystem?: EconomicSystem;
  economicSystemDate?: string;
  externalId?: string;
  telematics: boolean;
}

export type AccountSortColumn =
  (typeof AccountSortColumn)[keyof typeof AccountSortColumn];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountSortColumn = {
  FARM_ID: "FARM_ID",
  NAME: "NAME",
  EXTERNAL_ID: "EXTERNAL_ID",
  COUNTRY_CODE: "COUNTRY_CODE",
  ENABLED: "ENABLED",
  ACTIVATED: "ACTIVATED",
  DEACTIVATED: "DEACTIVATED",
} as const;

export interface CropTo {
  code: number;
  id?: number;
  key?: number;
  name?: string;
}

export type CropSortColumn =
  (typeof CropSortColumn)[keyof typeof CropSortColumn];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CropSortColumn = {
  NAME: "NAME",
} as const;

export interface DriverCompanyTo {
  id?: string;
}

export interface DriverTo {
  code: string;
  company?: DriverCompanyTo;
  id?: number;
  name?: string;
  personalNumber?: string;
  validFrom: string;
  validTo?: string;
}

export type DriverSortColumn =
  (typeof DriverSortColumn)[keyof typeof DriverSortColumn];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DriverSortColumn = {
  CODE: "CODE",
  NAME: "NAME",
  VALID_FROM: "VALID_FROM",
  VALID_TO: "VALID_TO",
} as const;

export interface EquipmentOperationTo {
  bonus?: number;
  id?: string;
  name?: string;
}

export interface EquipmentCompanyTo {
  id?: string;
}

export interface EquipmentTo {
  code: string;
  company?: EquipmentCompanyTo;
  id?: number;
  name?: string;
  operation?: EquipmentOperationTo;
  profileCode?: string;
  validFrom: string;
  validTo?: string;
  workingWidth?: number;
}

export type EquipmentSortColumn =
  (typeof EquipmentSortColumn)[keyof typeof EquipmentSortColumn];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EquipmentSortColumn = {
  CODE: "CODE",
  NAME: "NAME",
  WORKING_WIDTH: "WORKING_WIDTH",
  VALID_FROM: "VALID_FROM",
  VALID_TO: "VALID_TO",
} as const;

export type ImportState = (typeof ImportState)[keyof typeof ImportState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImportState = {
  STARTED: "STARTED",
  ERROR: "ERROR",
  COMPLETED: "COMPLETED",
} as const;

export interface DriveImportTo {
  finished?: string;
  id?: number;
  source?: Source;
  started?: string;
  state?: ImportState;
}

export interface ActionDriveTo {
  companyId: string;
  driveAffiliation: Affiliation;
  driveId: number;
  driverCode?: string;
  driverName?: string;
  equipmentCode?: string;
  equipmentName?: string;
  farmId: number;
  farmName: string;
  gpsUnit?: string;
  machineName?: string;
  operationCode?: string;
  operationName?: string;
}

export type ActionDrivesToParcels = { [key: string]: ActionDriveTo[] };

export interface ActionDrivesTo {
  parcels: ActionDrivesToParcels;
}

export interface SimpleMachineTo {
  categoryCode?: string;
  gpsUnit: string;
  group: MachineGroupCode;
  id?: number;
  licencePlate?: string;
  name?: string;
  operation?: DeviceOperationTo;
  profileCode?: string;
  workingWidth?: number;
}

export interface LogbookAggregatedMachineTo {
  cultivated: number;
  dateFrom: string;
  dateTo: string;
  distance: number;
  drives: LogbookAggregatedItemTo[];
  duration: number;
  machine: SimpleMachineTo;
  machineAffiliation: Affiliation;
  machineCompanyName?: string;
  times: LogbookAggregatedTimeTo[];
}

export type LogbookDriveSortColumn =
  (typeof LogbookDriveSortColumn)[keyof typeof LogbookDriveSortColumn];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LogbookDriveSortColumn = {
  FROM: "FROM",
  DRIVER_NAME: "DRIVER_NAME",
  OPERATION_NAME: "OPERATION_NAME",
  MACHINE_NAME: "MACHINE_NAME",
  DISTANCE: "DISTANCE",
  CULTIVATED: "CULTIVATED",
} as const;

export interface DriveSubjectTo {
  id: string;
  name: string;
}

export interface ValidationLogbookItemTo {
  complete?: boolean;
  isComplete: boolean;
  validationErrors: DriveValidationType[];
}

export interface LogbookAggregatedTimeTo {
  dateFrom: string;
  dateTo: string;
  type: Type;
}

export interface LogbookAggregatedTo {
  cultivated: number;
  dateFrom: string;
  dateTo: string;
  distance: number;
  driver?: DriverIdentityTo;
  drives: LogbookAggregatedItemTo[];
  duration: number;
  times: LogbookAggregatedTimeTo[];
}

export interface DriverLogbookItemTo {
  affiliation?: Affiliation;
  code?: string;
  companyName?: string;
  name?: string;
}

export interface SimpleParcelInfoTo {
  affiliation?: Affiliation;
  area?: number;
  blockNumber?: string;
  localName?: string;
  square?: string;
  subjectId?: string;
  subjectName?: string;
}

export interface OperationLogbookItemTo {
  code?: string;
  name?: string;
}

export interface MachineLogbookItemTo {
  affiliation?: Affiliation;
  companyName?: string;
  gpsUnit?: string;
  licencePlate?: string;
  name?: string;
}

export interface EquipmentLogbookItemTo {
  affiliation?: Affiliation;
  code?: string;
  companyName?: string;
  name?: string;
  width?: number;
}

export interface LogbookAggregatedItemTo {
  cropKey?: number;
  cropName?: string;
  cropType?: string;
  cultivated?: number;
  dateFrom: string;
  distance?: number;
  driver?: DriverLogbookItemTo;
  duration: number;
  equipment?: EquipmentLogbookItemTo;
  gpsUnit?: string;
  id: string;
  machine?: MachineLogbookItemTo;
  machineAffiliation: Affiliation;
  machineLicencePlate?: string;
  machineName?: string;
  operation?: string;
  parcel?: SimpleParcelInfoTo;
  parcelArea?: number;
  productionOperation?: OperationLogbookItemTo;
  source: Source;
  state: State;
  type: Type;
  validation: ValidationLogbookItemTo;
}

export type LogbookAggregatedSortColumn =
  (typeof LogbookAggregatedSortColumn)[keyof typeof LogbookAggregatedSortColumn];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LogbookAggregatedSortColumn = {
  FROM: "FROM",
  DRIVER_NAME: "DRIVER_NAME",
  MACHINE_NAME: "MACHINE_NAME",
  DURATION: "DURATION",
  DISTANCE: "DISTANCE",
  CULTIVATED: "CULTIVATED",
} as const;

export interface StreamingResponseBody {
  [key: string]: unknown;
}

export interface EconomicStatsTo {
  count: number;
  dateFrom?: string;
  dateTo?: string;
}

export interface DriverIdentityTo {
  affiliation?: Affiliation;
  code?: string;
  companyName?: string;
  name?: string;
}

export interface LogbookItemTo {
  bonus?: number;
  cropCode?: number;
  cropKey?: number;
  cropName?: string;
  cropType?: string;
  cultivated?: number;
  distance?: number;
  driver?: DriverIdentityTo;
  equipment?: EquipmentLogbookItemTo;
  from: string;
  gpsUnit?: string;
  id: string;
  machine?: MachineLogbookItemTo;
  machineAffiliation: Affiliation;
  machineLicencePlate?: string;
  machineName?: string;
  operation?: string;
  parcel?: SimpleParcelInfoTo;
  parcelArea?: number;
  productionOperation?: OperationLogbookItemTo;
  source: Source;
  state: State;
  to: string;
  type: Type;
}

export type LogbookSortColumn =
  (typeof LogbookSortColumn)[keyof typeof LogbookSortColumn];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LogbookSortColumn = {
  FROM: "FROM",
  DRIVER_NAME: "DRIVER_NAME",
  OPERATION_NAME: "OPERATION_NAME",
  MACHINE_NAME: "MACHINE_NAME",
  DISTANCE: "DISTANCE",
  CULTIVATED: "CULTIVATED",
} as const;

export interface MachineGroupTo {
  code: MachineGroupCode;
  value: string;
}

export interface MachineGroupCategoryTo {
  code: string;
  group: MachineGroupTo;
  id: number;
  name: string;
}

export type PositionSort = (typeof PositionSort)[keyof typeof PositionSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PositionSort = {
  STATE: "STATE",
  MACHINE_NAME: "MACHINE_NAME",
} as const;

export interface DailyPositionTo {
  date: string;
  driverCode?: string;
  driverName?: string;
  equipmentCode?: string;
  equipmentName?: string;
  geometry: Geometry;
  gpsUnit: string;
  licencePlate?: string;
  machineName?: string;
}

export type PositionState = (typeof PositionState)[keyof typeof PositionState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PositionState = {
  MOVING: "MOVING",
  ON: "ON",
  OFF: "OFF",
  MISSING: "MISSING",
} as const;

export interface PositionMachineTo {
  gpsUnit: string;
  group?: MachineGroupCode;
  licencePlate?: string;
  machineCode?: string;
  name?: string;
  operation?: string;
  type?: string;
}

export interface PositionEquipmentTo {
  code: string;
  name?: string;
}

export interface PositionDriverTo {
  code: string;
  name?: string;
}

export interface GeoJsonMultiPoint {
  coordinates: number[][];
  type: GeoJsonType;
}

export interface PositionDetailTo {
  azimuth?: number;
  driver?: PositionDriverTo;
  equipment?: PositionEquipmentTo;
  location: GeoJsonMultiPoint;
  machine?: PositionMachineTo;
  movementTime?: string;
  speed?: number;
  state: PositionState;
  time: string;
}

export type MachineGroupCode =
  (typeof MachineGroupCode)[keyof typeof MachineGroupCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MachineGroupCode = {
  COMBINE: "COMBINE",
  LOADER: "LOADER",
  TRUCK: "TRUCK",
  PASSENGER: "PASSENGER",
  TRACTOR: "TRACTOR",
  OTHER: "OTHER",
} as const;

export interface PositionDashboard {
  azimuth?: number;
  driverCode?: string;
  driverName?: string;
  equipmentCode?: string;
  equipmentName?: string;
  ignition: boolean;
  machineGpsUnit: string;
  machineGroup: MachineGroupCode;
  machineName?: string;
  movementTime?: string;
  position?: GeoJsonMultiPoint;
  speed: number;
  state: PositionState;
  time?: string;
}

export interface DeviceOperationTo {
  bonus?: number;
  id?: string;
  name?: string;
}

export interface DeviceCompanyTo {
  id?: string;
}

export interface MachineTo {
  category: MachineCategoryCode;
  categoryName?: string;
  company?: DeviceCompanyTo;
  gpsUnit: string;
  group: MachineGroupCode;
  groupName?: string;
  id?: number;
  keyCombination?: string;
  licencePlate?: string;
  machineCode?: string;
  name?: string;
  operation?: DeviceOperationTo;
  profileCode?: string;
  type?: string;
  unitPhoneNo?: string;
  validFrom: string;
  validTo?: string;
  workingWidth?: number;
}

export type MachineSortColumn =
  (typeof MachineSortColumn)[keyof typeof MachineSortColumn];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MachineSortColumn = {
  GPS_UNIT: "GPS_UNIT",
  NAME: "NAME",
  LICENCE_PLATE: "LICENCE_PLATE",
  WORKING_WIDTH: "WORKING_WIDTH",
  VALID_FROM: "VALID_FROM",
  VALID_TO: "VALID_TO",
} as const;

export interface ProductionOperationTo {
  bonus?: number;
  code?: string;
  id?: number;
  name?: string;
}

export interface OperationTo {
  code: TelematicsOperation;
  name: string;
}

export type KeyType = (typeof KeyType)[keyof typeof KeyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KeyType = {
  SUPPLIER: "SUPPLIER",
  CUSTOMER: "CUSTOMER",
  EQUIPMENT: "EQUIPMENT",
} as const;

export interface AccountCreateTo {
  automaticActions: boolean;
  catalogue?: Catalogue;
  countryCode: string;
  dashboard: boolean;
  economicSystem?: EconomicSystem;
  economicSystemDate?: string;
  enabled: boolean;
  externalId: string;
  farmId: string;
  name: string;
}

export interface DriveBatchUpdateBonusTo {
  bonus: number;
  drive: number[];
}

export interface DriveBatchUpdateDriverTo {
  drive: number[];
  driver: number;
}

export interface DriveBatchUpdateEquipmentTo {
  drive: number[];
  equipment: number;
}

export interface DriveBatchUpdateCustomerKeyTo {
  customerKeyId: number;
  drive: number[];
}

export interface DriveBatchUpdateProductionOperationTo {
  drive: number[];
  productionOperation: number;
}

export interface DriveBatchUpdateSubjectTo {
  drive: number[];
  subject?: string;
}

export interface HandworkCreateTo {
  bonus: number;
  driver?: string;
  productionOperation?: string;
  timeFrom: string;
  timeTo: string;
  winfas?: WinfasUpdateTo;
}

export interface DriveDetailOverlapTo {
  dateFrom: string;
  dateTo: string;
}

export interface DriveOverlapRequestTo {
  date: string;
  driver: string;
}

export interface DriveUpdateStateTo {
  drive: number[];
  state: State;
}

export interface DriveAreaTo {
  area?: number;
}

export interface DriveRecalculateTo {
  equipment?: string;
  gpsUnit: string;
  workingWidth?: number;
}

export interface DriveSeparationIntervalTo {
  timeFrom: string;
  timeTo: string;
}

export interface DriveSeparationIntervalsTo {
  intervals: DriveSeparationIntervalTo[];
}

export interface DriveSeparationTimeTo {
  time: string;
}

export interface DriveUpdateTo {
  bonus: number;
  cultivated?: number;
  distance?: number;
  driver?: string;
  equipment?: string;
  gpsUnit: string;
  operation: TelematicsOperation;
  parcelAffiliation?: Affiliation;
  parcelId?: string;
  productionOperation?: string;
  season?: number;
  timeFrom?: string;
  timeTo?: string;
  workingWidth?: number;
}

export type GeoJsonLineString = Geometry;

export type DriveValidationType =
  (typeof DriveValidationType)[keyof typeof DriveValidationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DriveValidationType = {
  CENTER_EXISTS: "CENTER_EXISTS",
  CROP_EXISTS: "CROP_EXISTS",
  CULTIVATED_NOT_ZERO: "CULTIVATED_NOT_ZERO",
  DISTANCE_NOT_ZERO: "DISTANCE_NOT_ZERO",
  DURATION_NOT_ZERO: "DURATION_NOT_ZERO",
  DRIVER_EXISTS: "DRIVER_EXISTS",
  MACHINE_AFFILIATION_COMPANY: "MACHINE_AFFILIATION_COMPANY",
  PARCEL_EXISTS: "PARCEL_EXISTS",
  PRODUCTION_OPERATION_EXISTS: "PRODUCTION_OPERATION_EXISTS",
  SUPPLIER_KEY_EXISTS: "SUPPLIER_KEY_EXISTS",
  SUPPLIER_KEY_VALID: "SUPPLIER_KEY_VALID",
  CUSTOMER_KEY_EXISTS: "CUSTOMER_KEY_EXISTS",
  CUSTOMER_KEY_VALID: "CUSTOMER_KEY_VALID",
  CLIENT_EXISTS: "CLIENT_EXISTS",
  CLIENT_NOT_EXISTS: "CLIENT_NOT_EXISTS",
  TIMES_DONT_OVERLAP: "TIMES_DONT_OVERLAP",
} as const;

export interface DriveDetailValidationTo {
  complete?: boolean;
  isComplete: boolean;
  validationErrors: DriveValidationType[];
}

export interface DriveDetailPartTo {
  dateFrom: string;
  dateTo: string;
  geometry?: Geometry;
}

export interface DriveDetailOperationTo {
  code?: string;
  name?: string;
}

export interface DriveDetailMachineTo {
  affiliation?: Affiliation;
  companyName?: string;
  gpsUnit?: string;
  name?: string;
}

export interface DriveDetailFarmTo {
  companyNumber?: number;
  farmId?: number;
}

export interface DriveDetailEquipmentTo {
  affiliation?: Affiliation;
  code?: string;
  companyName?: string;
  name?: string;
}

export interface DriveDetailDriverTo {
  affiliation?: Affiliation;
  bonus?: number;
  code?: string;
  companyName?: string;
  isEmployee: boolean;
  name?: string;
  personalNumber?: string;
}

export interface DriveDetailActionTo {
  actionCode?: string;
  actionId?: string;
}

export interface DriveCreateTo {
  bonus: number;
  cultivated?: number;
  distance?: number;
  driver?: string;
  equipment?: string;
  gpsUnit: string;
  operation: TelematicsOperation;
  parcelId?: string;
  productionOperation?: string;
  season?: number;
  timeFrom: string;
  timeTo: string;
  workingWidth?: number;
}

export type Type = (typeof Type)[keyof typeof Type];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Type = {
  AUTOMATIC: "AUTOMATIC",
  UPDATED: "UPDATED",
  MANUAL: "MANUAL",
  SEPARATED: "SEPARATED",
  HANDWORK: "HANDWORK",
} as const;

export type State = (typeof State)[keyof typeof State];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const State = {
  DEFERRED: "DEFERRED",
  NOT_APPROVED: "NOT_APPROVED",
  APPROVED: "APPROVED",
  THIRD_PARTY_ACKNOWLEDGED: "THIRD_PARTY_ACKNOWLEDGED",
} as const;

export type Source = (typeof Source)[keyof typeof Source];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Source = {
  MANUAL: "MANUAL",
  EUROWAG: "EUROWAG",
  ISOXML: "ISOXML",
} as const;

export interface ParcelInfoTo {
  affiliation?: Affiliation;
  area?: number;
  blockNumber?: string;
  geometry?: GeoJsonPolygon;
  localName?: string;
  square?: string;
  subjectId?: string;
  subjectName?: string;
}

export interface MachineDrivePartDetailTo {
  affiliation?: Affiliation;
  categoryCode?: string;
  companyName?: string;
  gpsUnit?: string;
  keyCombination?: string;
  name?: string;
}

export type GeoJsonType = (typeof GeoJsonType)[keyof typeof GeoJsonType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeoJsonType = {
  Point: "Point",
  MultiPoint: "MultiPoint",
  LinearRing: "LinearRing",
  LineString: "LineString",
  MultiLineString: "MultiLineString",
  Polygon: "Polygon",
  MultiPolygon: "MultiPolygon",
  GeometryCollection: "GeometryCollection",
} as const;

export interface GeoJsonPolygon {
  coordinates: number[][][];
  geometries: GeoJson[];
  type: GeoJsonType;
}

export type GeoJsonMultiLineString = Geometry;

export type GeoJsonCoordinatesItem = { [key: string]: unknown };

export interface GeoJson {
  coordinates: GeoJsonCoordinatesItem[];
  type: GeoJsonType;
}

export interface EquipmentDrivePartDetailTo {
  affiliation?: Affiliation;
  code?: string;
  companyName?: string;
  keyCombination?: string;
  name?: string;
}

export interface DrivePartDetailOperationTo {
  code?: string;
  name?: string;
}

export interface DrivePartDetailFarmTo {
  companyNumber?: number;
  farmId?: number;
}

export type Affiliation = (typeof Affiliation)[keyof typeof Affiliation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Affiliation = {
  COMPANY: "COMPANY",
  EXTERNAL: "EXTERNAL",
  NONE: "NONE",
} as const;

export interface DriverDrivePartDetailTo {
  affiliation?: Affiliation;
  bonus?: number;
  code?: string;
  companyName?: string;
  isEmployee: boolean;
  name?: string;
  personalNumber?: string;
}

export interface ActionDriveDetailPartTo {
  activityId?: string;
  area?: number;
  distance?: number;
}

export type TelematicsOperation =
  (typeof TelematicsOperation)[keyof typeof TelematicsOperation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TelematicsOperation = {
  PARCEL: "PARCEL",
  TRANSFER: "TRANSFER",
  TRANSPORT: "TRANSPORT",
  REPAIR: "REPAIR",
  HANDWORK: "HANDWORK",
} as const;

export interface DriveDetailTo {
  actions: DriveDetailActionTo[];
  centerKey?: string;
  cropKey?: number;
  cropName?: string;
  cropType?: string;
  cultivated?: number;
  dateFrom: string;
  distance?: number;
  drivePart: DriveDetailPartTo[];
  driver: DriveDetailDriverTo;
  duration: number;
  equipment?: DriveDetailEquipmentTo;
  equipmentAffiliation?: Affiliation;
  equipmentCode?: string;
  equipmentCompanyName?: string;
  equipmentName?: string;
  farm: DriveDetailFarmTo;
  geometryPerDay?: Geometry;
  gpsUnit?: string;
  id: number;
  machine?: DriveDetailMachineTo;
  machineAffiliation: Affiliation;
  machineCategoryCode?: string;
  machineName?: string;
  operation: TelematicsOperation;
  parcel?: ParcelInfoTo;
  parcelId?: string;
  productionOperation?: DriveDetailOperationTo;
  season?: number;
  source: Source;
  state: State;
  type: Type;
  validation: DriveDetailValidationTo;
  workingWidth?: number;
}

export interface DrivePartDetailTo {
  action: ActionDriveDetailPartTo;
  centerKey?: string;
  cropKey?: number;
  cropName?: string;
  cropType?: string;
  driver: DriverDrivePartDetailTo;
  equipment?: EquipmentDrivePartDetailTo;
  equipmentAffiliation?: Affiliation;
  equipmentCode?: string;
  equipmentCompanyName?: string;
  equipmentKeyCombination?: string;
  equipmentName?: string;
  farm: DrivePartDetailFarmTo;
  geometry?: Geometry;
  geometryEnd?: Geometry;
  geometryPerDay?: Geometry;
  geometryStart?: Geometry;
  geometryStartAzimuth?: number;
  gpsUnit?: string;
  id?: number;
  machine?: MachineDrivePartDetailTo;
  machineAffiliation: Affiliation;
  machineCategoryCode?: string;
  machineKeyCombination?: string;
  machineName?: string;
  operation: TelematicsOperation;
  parcel?: ParcelInfoTo;
  parcelId?: string;
  productionOperation?: DrivePartDetailOperationTo;
  season?: number;
  source: Source;
  state: State;
  timeFrom: string;
  timeTo: string;
  type: Type;
  workingWidth?: number;
}

export interface DrivePartCreateTo {
  bonus: number;
  cultivated?: number;
  distance?: number;
  driver?: string;
  equipment?: string;
  gpsUnit: string;
  operation: TelematicsOperation;
  parcelId?: string;
  productionOperation?: string;
  timeFrom: string;
  timeTo: string;
  workingWidth?: number;
}

export type EconomicSystem =
  (typeof EconomicSystem)[keyof typeof EconomicSystem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EconomicSystem = {
  TOS: "TOS",
  SIDUS: "SIDUS",
} as const;

export type Catalogue = (typeof Catalogue)[keyof typeof Catalogue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Catalogue = {
  SIDUS: "SIDUS",
  WINFAS: "WINFAS",
} as const;

export interface AccountTo {
  activated?: string;
  automaticActions: boolean;
  catalogue?: Catalogue;
  countryCode: string;
  dashboard: boolean;
  deactivated?: string;
  economicSystem?: EconomicSystem;
  economicSystemDate?: string;
  enabled: boolean;
  externalId?: string;
  farmId: string;
  name: string;
  zoneId?: string;
}

export interface AccountUpdateTo {
  automaticActions?: boolean;
  catalogue?: Catalogue;
  dashboard?: boolean;
  economicSystem?: EconomicSystem;
  economicSystemDate?: string;
  enabled?: boolean;
  externalId?: string;
  farmId: string;
}

export interface DriverUpdateTo {
  name: string;
}

export interface EquipmentUpdateTo {
  name: string;
  operationCode: string;
  workingWidth: number;
}

export interface WinfasClientTo {
  idNumber: string;
  name: string;
}

export interface DriveKeyTo {
  id?: number;
  key?: string;
  name?: string;
  validity?: boolean;
}

export interface DriveKeysTo {
  client?: string;
  customerKey?: DriveKeyTo;
  equipmentKey?: DriveKeyTo;
  supplierKey?: DriveKeyTo;
}

export interface WinfasTo {
  client?: WinfasClientTo;
  keys?: DriveKeysTo;
}

export interface WinfasClientUpdateTo {
  idNumber: string;
  name: string;
}

export interface DriveUpdateKeyTo {
  customerKeyId?: number;
  equipmentKeyId?: number;
  supplierKeyId?: number;
}

export interface WinfasUpdateTo {
  client?: WinfasClientUpdateTo;
  keys: DriveUpdateKeyTo;
}

export type MachineCategoryCode =
  (typeof MachineCategoryCode)[keyof typeof MachineCategoryCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MachineCategoryCode = {
  AJE: "AJE",
  CIS: "CIS",
  KNA: "KNA",
  KRM: "KRM",
  MAN: "MAN",
  NAK: "NAK",
  NAV: "NAV",
  NNN: "NNN",
  OMA: "OMA",
  OST: "OST",
  OSA: "OSA",
  POS: "POS",
  PED: "PED",
  SKM: "SKM",
  SKR: "SKR",
  SPC: "SPC",
  SPP: "SPP",
  SRN: "SRN",
  T0: "T0",
  T1: "T1",
  T2: "T2",
  T3: "T3",
  T4: "T4",
  TVS: "TVS",
  ZAS: "ZAS",
  ZLK: "ZLK",
  ZSB: "ZSB",
  ZSS: "ZSS",
  XXX: "XXX",
} as const;

export interface MachineUpdateTo {
  category: MachineCategoryCode;
  licencePlate: string;
  name: string;
  operationCode: string;
  workingWidth: number;
}

/**
 * GeoJSON representation of a Geometry.
 */
export interface Geometry {
  coordinates?: number[][];
  type?: string;
}

export const updateMachine = <TData = AxiosResponse<void>>(
  machineId: number,
  machineUpdateTo: MachineUpdateTo,
  params?: UpdateMachineParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.put(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/machines/${machineId}`,
    machineUpdateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const get = <TData = AxiosResponse<WinfasTo>>(
  id: number,
  params: GetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}/winfas`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const updateKeys = <TData = AxiosResponse<WinfasTo>>(
  id: number,
  winfasUpdateTo: WinfasUpdateTo,
  params: UpdateKeysParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.put(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}/winfas`,
    winfasUpdateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const updateEquipment = <TData = AxiosResponse<void>>(
  equipmentId: number,
  equipmentUpdateTo: EquipmentUpdateTo,
  params?: UpdateEquipmentParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.put(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/equipments/${equipmentId}`,
    equipmentUpdateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const updateDriver = <TData = AxiosResponse<void>>(
  driverId: number,
  driverUpdateTo: DriverUpdateTo,
  params?: UpdateDriverParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.put(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/drivers/${driverId}`,
    driverUpdateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getAll8 = <TData = AxiosResponse<AccountTo[]>>(
  params?: GetAll8Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/admin/account`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const put = <TData = AxiosResponse<AccountTo[]>>(
  accountUpdateTo: AccountUpdateTo[],
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.put(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/admin/account`,
    accountUpdateTo,
    options,
  );
};

export const create2 = <TData = AxiosResponse<AccountTo>>(
  accountCreateTo: AccountCreateTo,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/admin/account`,
    accountCreateTo,
    options,
  );
};

export const patch = <TData = AxiosResponse<AccountTo[]>>(
  accountUpdateTo: AccountUpdateTo[],
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/admin/account`,
    accountUpdateTo,
    options,
  );
};

/**
 * Return list of all drives
 */
export const logbook = <TData = AxiosResponse<LogbookItemTo[]>>(
  params: LogbookParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const create = <TData = AxiosResponse<DrivePartDetailTo>>(
  drivePartCreateTo: DrivePartCreateTo,
  params: CreateParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook`,
    drivePartCreateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Export drives to TOS
 */
export const tosExport = <TData = AxiosResponse<void>>(
  params: TosExportParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/tos`,
    undefined,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Export selected drives to TOS
 */
export const tosExcelExport = <TData = AxiosResponse<Blob>>(
  params: TosExcelExportParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/tos/excel`,
    undefined,
    {
      responseType: "blob",
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Return list of all aggregated drives
 */
export const logbookByDrivers = <TData = AxiosResponse<LogbookAggregatedTo[]>>(
  params: LogbookByDriversParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const create1 = <TData = AxiosResponse<DriveDetailTo>>(
  driveCreateTo: DriveCreateTo,
  params: Create1Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated`,
    driveCreateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Return detail of a single drive. Drive trajectory is sent in GeoJSON format.
 */
export const driveDetail1 = <TData = AxiosResponse<DriveDetailTo>>(
  id: number,
  params: DriveDetail1Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const update = <TData = AxiosResponse<DriveDetailTo>>(
  id: number,
  driveUpdateTo: DriveUpdateTo,
  params: UpdateParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}`,
    driveUpdateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const _delete = <TData = AxiosResponse<void>>(
  id: number,
  params: _DeleteParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.delete(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const separateDriveByTime = <TData = AxiosResponse<void>>(
  id: number,
  driveSeparationTimeTo: DriveSeparationTimeTo,
  params: SeparateDriveByTimeParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}/separation/time`,
    driveSeparationTimeTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const separateDriveByInterval = <TData = AxiosResponse<void>>(
  id: number,
  driveSeparationIntervalsTo: DriveSeparationIntervalsTo,
  params: SeparateDriveByIntervalParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}/separation/interval`,
    driveSeparationIntervalsTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const recalculateArea = <TData = AxiosResponse<DriveAreaTo>>(
  id: number,
  driveRecalculateTo: DriveRecalculateTo,
  params: RecalculateAreaParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}/area`,
    driveRecalculateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const setState = <TData = AxiosResponse<void>>(
  driveUpdateStateTo: DriveUpdateStateTo,
  params: SetStateParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/state`,
    driveUpdateStateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getDriverOverlaps = <
  TData = AxiosResponse<DriveDetailOverlapTo[]>,
>(
  driveOverlapRequestTo: DriveOverlapRequestTo,
  params: GetDriverOverlapsParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/overlaps`,
    driveOverlapRequestTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const createHandwork = <TData = AxiosResponse<DriveDetailTo>>(
  handworkCreateTo: HandworkCreateTo,
  params: CreateHandworkParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/handwork`,
    handworkCreateTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const updateSubject = <TData = AxiosResponse<void>>(
  driveBatchUpdateSubjectTo: DriveBatchUpdateSubjectTo,
  params: UpdateSubjectParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/batch/subject`,
    driveBatchUpdateSubjectTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const updateProductionOperation = <TData = AxiosResponse<void>>(
  driveBatchUpdateProductionOperationTo: DriveBatchUpdateProductionOperationTo,
  params: UpdateProductionOperationParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/batch/operation`,
    driveBatchUpdateProductionOperationTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const updateCustomerKey = <TData = AxiosResponse<void>>(
  driveBatchUpdateCustomerKeyTo: DriveBatchUpdateCustomerKeyTo,
  params: UpdateCustomerKeyParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/batch/key/customer`,
    driveBatchUpdateCustomerKeyTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const updateEquipment1 = <TData = AxiosResponse<void>>(
  driveBatchUpdateEquipmentTo: DriveBatchUpdateEquipmentTo,
  params: UpdateEquipment1Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/batch/equipment`,
    driveBatchUpdateEquipmentTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const updateDriver1 = <TData = AxiosResponse<void>>(
  driveBatchUpdateDriverTo: DriveBatchUpdateDriverTo,
  params: UpdateDriver1Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/batch/driver`,
    driveBatchUpdateDriverTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const updateBonus = <TData = AxiosResponse<void>>(
  driveBatchUpdateBonusTo: DriveBatchUpdateBonusTo,
  params: UpdateBonusParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/batch/bonus`,
    driveBatchUpdateBonusTo,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getKeysCombinations = <TData = AxiosResponse<DriveKeyTo[]>>(
  params: GetKeysCombinationsParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/winfas/key/combinations`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getClients = <TData = AxiosResponse<WinfasClientTo[]>>(
  params: GetClientsParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/winfas/clients`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Returns list of all operations.
 */
export const getAll = <TData = AxiosResponse<OperationTo[]>>(
  params?: GetAllParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/operations`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Returns an operation entry based on the provided key.
 */
export const valueForKey = <TData = AxiosResponse<OperationTo>>(
  key: string,
  params?: ValueForKeyParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/operations/${key}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getAll1 = <TData = AxiosResponse<ProductionOperationTo[]>>(
  params?: GetAll1Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/operations/production`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getAll2 = <TData = AxiosResponse<MachineTo[]>>(
  params: GetAll2Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/machines`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getAll3 = <TData = AxiosResponse<PositionDetailTo[]>>(
  params: GetAll3Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/machines/position`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Returns trajectories for a given time range. Supports additional filtering by machine and driver.
 */
export const getPositionHistory = <TData = AxiosResponse<DailyPositionTo[]>>(
  params: GetPositionHistoryParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/machines/position/history`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getDashboard = <TData = AxiosResponse<PositionDashboard[]>>(
  params: GetDashboardParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/machines/position/dashboard`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getMachineGroups = <TData = AxiosResponse<MachineGroupTo[]>>(
  params?: GetMachineGroupsParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/machines/groups`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getMachineByGps = <TData = AxiosResponse<MachineTo>>(
  machineGpsUnit: string,
  params: GetMachineByGpsParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/machines/gps/${machineGpsUnit}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getMachineCategories = <
  TData = AxiosResponse<MachineGroupCategoryTo[]>,
>(
  params?: GetMachineCategoriesParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/machines/categories`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Return detail of a single drive. Drive trajectory is sent in GeoJSON format.
 */
export const driveDetail = <TData = AxiosResponse<DrivePartDetailTo>>(
  id: number,
  params: DriveDetailParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/${id}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Get current data stats ready to export
 */
export const tosState = <TData = AxiosResponse<EconomicStatsTo>>(
  params: TosStateParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/tos/status`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Get connection status to the third party database
 */
export const tosHealth = <TData = AxiosResponse<boolean>>(
  params: TosHealthParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/tos/health`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Returns file of logbook records by given criteria
 */
export const logbookExport = <TData = AxiosResponse<StreamingResponseBody>>(
  params: LogbookExportParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/export/excel`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Returns file of aggregated logbook records by given criteria
 */
export const logbookAggregatedRecordsExport = <
  TData = AxiosResponse<StreamingResponseBody>,
>(
  params: LogbookAggregatedRecordsExportParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/export/aggregated/records/excel`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Returns file of aggregated logbook records by given criteria
 */
export const logbookAggregatedExport = <
  TData = AxiosResponse<StreamingResponseBody>,
>(
  params: LogbookAggregatedExportParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/export/aggregated/excel`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * @deprecated
 */
export const getClients1 = <TData = AxiosResponse<WinfasClientTo[]>>(
  id: number,
  params: GetClients1Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}/winfas/clients`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getDriveOverlaps = <TData = AxiosResponse<DriveDetailOverlapTo[]>>(
  id: number,
  params: GetDriveOverlapsParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}/overlaps`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * @deprecated
 */
export const getKeys = <TData = AxiosResponse<DriveKeysTo>>(
  id: number,
  params: GetKeysParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}/key`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * @deprecated
 */
export const getKeysCombinations1 = <TData = AxiosResponse<DriveKeyTo[]>>(
  id: number,
  params: GetKeysCombinations1Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/${id}/key/combinations`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Return list of all drive subjects
 */
export const getDriveSubjects = <TData = AxiosResponse<DriveSubjectTo[]>>(
  params: GetDriveSubjectsParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/subject`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Return list of all aggregated records
 */
export const aggregatedRecords = <
  TData = AxiosResponse<LogbookAggregatedItemTo[]>,
>(
  params: AggregatedRecordsParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/records`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Return list of all aggregated drives by machine
 */
export const logbookByMachines = <
  TData = AxiosResponse<LogbookAggregatedMachineTo[]>,
>(
  params: LogbookByMachinesParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/aggregated/machine`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * Return all drives for provided action
 */
export const logbook1 = <TData = AxiosResponse<ActionDrivesTo>>(
  actionId: number,
  params: Logbook1Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/logbook/action/${actionId}/drive`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getAll4 = <TData = AxiosResponse<DriveImportTo[]>>(
  params?: GetAll4Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/import`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getState = <TData = AxiosResponse<DriveImportTo>>(
  params?: GetStateParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/import/state`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getAll5 = <TData = AxiosResponse<EquipmentTo[]>>(
  params: GetAll5Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/equipments`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getEquipmentByCode = <TData = AxiosResponse<EquipmentTo>>(
  equipmentCode: string,
  params: GetEquipmentByCodeParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/equipments/code/${equipmentCode}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getAll6 = <TData = AxiosResponse<DriverTo[]>>(
  params: GetAll6Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/drivers`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getDriverByCode = <TData = AxiosResponse<DriverTo>>(
  driverCode: string,
  params: GetDriverByCodeParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/drivers/code/${driverCode}`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getAll7 = <TData = AxiosResponse<CropTo[]>>(
  params?: GetAll7Params,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/crops`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getCountries = <TData = AxiosResponse<string[]>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/admin/account/country`,
    options,
  );
};

export const getAccount = <TData = AxiosResponse<AccountTo>>(
  params: GetAccountParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/account`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export const getStatus = <TData = AxiosResponse<AccountStatusTo>>(
  params: GetStatusParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `https://telematics-api.cf-nightly.dev.cleverfarm.cz/api/account/status`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

export type UpdateMachineResult = AxiosResponse<void>;
export type GetResult = AxiosResponse<WinfasTo>;
export type UpdateKeysResult = AxiosResponse<WinfasTo>;
export type UpdateEquipmentResult = AxiosResponse<void>;
export type UpdateDriverResult = AxiosResponse<void>;
export type GetAll8Result = AxiosResponse<AccountTo[]>;
export type PutResult = AxiosResponse<AccountTo[]>;
export type Create2Result = AxiosResponse<AccountTo>;
export type PatchResult = AxiosResponse<AccountTo[]>;
export type LogbookResult = AxiosResponse<LogbookItemTo[]>;
export type CreateResult = AxiosResponse<DrivePartDetailTo>;
export type TosExportResult = AxiosResponse<void>;
export type TosExcelExportResult = AxiosResponse<Blob>;
export type LogbookByDriversResult = AxiosResponse<LogbookAggregatedTo[]>;
export type Create1Result = AxiosResponse<DriveDetailTo>;
export type DriveDetail1Result = AxiosResponse<DriveDetailTo>;
export type UpdateResult = AxiosResponse<DriveDetailTo>;
export type _DeleteResult = AxiosResponse<void>;
export type SeparateDriveByTimeResult = AxiosResponse<void>;
export type SeparateDriveByIntervalResult = AxiosResponse<void>;
export type RecalculateAreaResult = AxiosResponse<DriveAreaTo>;
export type SetStateResult = AxiosResponse<void>;
export type GetDriverOverlapsResult = AxiosResponse<DriveDetailOverlapTo[]>;
export type CreateHandworkResult = AxiosResponse<DriveDetailTo>;
export type UpdateSubjectResult = AxiosResponse<void>;
export type UpdateProductionOperationResult = AxiosResponse<void>;
export type UpdateCustomerKeyResult = AxiosResponse<void>;
export type UpdateEquipment1Result = AxiosResponse<void>;
export type UpdateDriver1Result = AxiosResponse<void>;
export type UpdateBonusResult = AxiosResponse<void>;
export type GetKeysCombinationsResult = AxiosResponse<DriveKeyTo[]>;
export type GetClientsResult = AxiosResponse<WinfasClientTo[]>;
export type GetAllResult = AxiosResponse<OperationTo[]>;
export type ValueForKeyResult = AxiosResponse<OperationTo>;
export type GetAll1Result = AxiosResponse<ProductionOperationTo[]>;
export type GetAll2Result = AxiosResponse<MachineTo[]>;
export type GetAll3Result = AxiosResponse<PositionDetailTo[]>;
export type GetPositionHistoryResult = AxiosResponse<DailyPositionTo[]>;
export type GetDashboardResult = AxiosResponse<PositionDashboard[]>;
export type GetMachineGroupsResult = AxiosResponse<MachineGroupTo[]>;
export type GetMachineByGpsResult = AxiosResponse<MachineTo>;
export type GetMachineCategoriesResult = AxiosResponse<
  MachineGroupCategoryTo[]
>;
export type DriveDetailResult = AxiosResponse<DrivePartDetailTo>;
export type TosStateResult = AxiosResponse<EconomicStatsTo>;
export type TosHealthResult = AxiosResponse<boolean>;
export type LogbookExportResult = AxiosResponse<StreamingResponseBody>;
export type LogbookAggregatedRecordsExportResult =
  AxiosResponse<StreamingResponseBody>;
export type LogbookAggregatedExportResult =
  AxiosResponse<StreamingResponseBody>;
export type GetClients1Result = AxiosResponse<WinfasClientTo[]>;
export type GetDriveOverlapsResult = AxiosResponse<DriveDetailOverlapTo[]>;
export type GetKeysResult = AxiosResponse<DriveKeysTo>;
export type GetKeysCombinations1Result = AxiosResponse<DriveKeyTo[]>;
export type GetDriveSubjectsResult = AxiosResponse<DriveSubjectTo[]>;
export type AggregatedRecordsResult = AxiosResponse<LogbookAggregatedItemTo[]>;
export type LogbookByMachinesResult = AxiosResponse<
  LogbookAggregatedMachineTo[]
>;
export type Logbook1Result = AxiosResponse<ActionDrivesTo>;
export type GetAll4Result = AxiosResponse<DriveImportTo[]>;
export type GetStateResult = AxiosResponse<DriveImportTo>;
export type GetAll5Result = AxiosResponse<EquipmentTo[]>;
export type GetEquipmentByCodeResult = AxiosResponse<EquipmentTo>;
export type GetAll6Result = AxiosResponse<DriverTo[]>;
export type GetDriverByCodeResult = AxiosResponse<DriverTo>;
export type GetAll7Result = AxiosResponse<CropTo[]>;
export type GetCountriesResult = AxiosResponse<string[]>;
export type GetAccountResult = AxiosResponse<AccountTo>;
export type GetStatusResult = AxiosResponse<AccountStatusTo>;
